<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_issuance') || isAccountant() || isAdmin()"
      wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout
                wrap
                style="display: flex;align-items: center;"
              >
                <v-flex
                  xs12
                  md3
                >
                  <v-text-field
                    v-model="voucherSearch.workpieceCode"
                    :label="$t('evoucher.issuance.code')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <!-- <v-flex
                  xs12
                  md3
                >
                  <v-select
                    :items="status"
                    v-model="voucherSearch.issuanceStatus"
                    :label="$t('evoucher.issuance.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex> -->
                <v-flex
                  xs12
                  md3
                >
                  <v-menu
                    ref="menu"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      @input="menuStartDate = false"/>
                  </v-menu>
                </v-flex>
                <v-menu
                  ref="menu"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      :label="$t('evoucher.issuance.toDate')"
                      persistent-hint
                      @blur="date = parseDate(endDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    @input="menuEndDate = false"/>
                </v-menu>
                <v-flex
                  xs12
                  md3
                >
                  <v-select
                    :items="typeList"
                    v-model="voucherSearch.issuanceType"
                    :label="$t('evoucher.issuance.type')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md3
                >
                  <v-select
                    v-model="voucherSearch.issuanceModel"
                    :items="modelList"
                    :label="'Voucher'"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search"/>
                </span>
                <span style="text-transform: none;">{{ $t('common.search') }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="forConasi ? $t('workpiece.listVoucher') : $t('workpiece.issuedVoucherList')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="forConasi && !isAccountant() && (hasAction('create_issuance') && !isEntityDisabled() || isAdmin())"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddEVoucherIssuance()"
            >
              <v-icon>mdi-plus</v-icon>
              <span style="text-transform: none;">{{ $t("common.add") }}</span>
            </v-btn>
            <v-btn
              v-if="!isAccountant() && isEntityType(entityType.AGENT) && !isNull(GET_CURRENT_ACCOUNT.parent_entity)"
              color="success"
              dark
              style="float: right;"
              @click="onShowTransferVoucherBetweenAgentModal"
            >
              <v-icon class="mr-2">mdi-bank-transfer-out</v-icon>
              <span style="text-transform: none;">{{ $t('evoucher.transferVoucher') }}</span>
            </v-btn>
            <v-btn
              v-if="(hasAction('delivery_voucher') && !isAccountant() && !isEntityDisabled()) || isAdmin()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAssignVoucher"
            >
              <v-icon class="mr-2">mdi-transfer</v-icon>
              <span style="text-transform: none;">{{ $t("evoucher.voucherDistribution") }}</span>
            </v-btn>
            <v-btn
              v-if="hasAction('delivery_voucher') && !isAccountant() && (isAgent() || !forConasi) && !isEntityDisabled()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalDeliveryVoucherByExcelMode"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              <span style="text-transform: none;">{{ $t('evoucher.inputExcelToDistribute') }}</span>
            </v-btn>
            <v-btn
              v-if="(hasAction('create_voucher') && !isEntityDisabled() || isAdmin()) && !isAccountant() && !forConasi"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddEVoucherIssuance()"
            >
              <v-icon>mdi-plus</v-icon>
              <span style="text-transform: none;">{{ $t("evoucher.addNewVoucher") }}</span>
            </v-btn>
            <v-btn
              v-if="(hasAction('create_voucher') && !isEntityDisabled() && forConasi) || isAdmin()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddListEvoucher(createVoucherType.LIST)"
            >
              <v-icon>mdi-playlist-plus</v-icon>
              <span style="text-transform: none;">{{ $t("evoucher.assignVoucher") }}</span>
            </v-btn>
            <v-btn
              v-if="forConasi && !isSupplier() && !isAccountant() && hasAction('create_request')"
              color="success"
              dark
              style="float: right;"
              @click="requestEVoucherToParent($event)"
            >
              <v-icon
                size="15"
                class="mr-2">mdi-message-bulleted</v-icon>
              <span style="text-transform: none;">{{ $t("issueVoucherRequest.requestTitle") }}</span>
            </v-btn>
          </template>
          <div @mouseleave="$refs.tblIssuranceList.expanded=[]">
            <v-data-table
              ref="tblIssuranceList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="voucherIssuanceHeaders"
              :items="workpieceList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }">
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)" />
              </template>
              <template v-slot:items="props">
                <tr
                  :style="`background-color: ${props.item.isDisabled ? '#e5e5e5' : ''}`"
                  @click="(isSupplier() || isAdmin()) ? props.expanded=!props.expanded : null"
                  @mouseover="(isSupplier() || isAdmin()) ? props.expanded=true : null">
                  <td class="text-xs-center">{{ props.item.stt }}</td>
                  <td class="text-xs-center">
                    <img
                      :src="props.item.image ? props.item.image : noImg"
                      width="60"
                      height="40"
                      style="border: 2px solid #fff;">
                  </td>
                  <td class="text-xs-center">{{ props.item.code }}</td>
                  <td class="text-xs-center">{{ props.item.name }}</td>
                  <td
                    v-if="isSupplier() || isAdmin()"
                    class="text-xs-center">{{ props.item.quantity }}</td>
                  <td
                    v-if="forConasi || isAdmin()"
                    class="text-xs-center">{{ (isSupplier() || isAdmin()) ? props.item.statisticsQuantity.total_voucher_created : props.item.statisticsQuantity.total_quantity_of_agent }}</td>
                  <td
                    v-if="(forConasi && isSupplier()) || isAdmin()"
                    class="text-xs-center">{{ (props.item.type == VoucherType.PROMOTION) ? '' : props.item.quantity - props.item.statisticsQuantity.total_voucher_created }}</td>
                  <td
                    v-if="!isAgent()"
                    class="text-xs-center">{{ (props.item.type == VoucherType.PROMOTION) ? '' : props.item.statisticsQuantity.quantity_distributed_to_agent }}</td>
                  <td
                    v-else
                    class="text-xs-center">{{ (props.item.type == VoucherType.PROMOTION) ? '' : props.item.quantityRemainOfAgent }}</td>
                  <td class="text-xs-center">{{ props.item.statisticsQuantity.quantity_distributed_to_customer }}</td>
                  <td class="text-xs-center">{{ props.item.statisticsQuantity.quantity_used_vouchers }}</td>
                  <!-- <td class="text-xs-center">{{ '---' }}</td> -->
                  <td class="text-xs-center">{{ getVoucherType(props.item.type) }}</td>
                  <td class="text-xs-center">{{ getVoucherModel(props.item.voucher) }}</td>
                  <td class="text-xs-center">{{ getValue(props.item.value, props.item.type) }}</td>
                  <td
                    class="text-xs-left"
                    style="white-space: nowrap;">
                    <!-- Create voucher -->
                    <v-tooltip
                      v-if="!props.item.isDisabled && props.item.type != VoucherType.PROMOTION && props.item.statisticsQuantity.not_created > 0 && !isAccountant() && (hasAction('create_voucher') && !isEntityDisabled() || isAdmin())"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAddEVoucher(createVoucherType.LIST, props.item)"
                        >
                          <v-icon>mdi-playlist-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.add") }}</span>
                    </v-tooltip>
                    <!-- Distribute voucher -->
                    <v-tooltip
                      v-if="(props.item.type != VoucherType.PROMOTION) && !isAccountant() && !isEntityDisabled()"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="purple"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAssignVoucher(props.item)"
                        >
                          <v-icon>mdi-transfer</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("evoucher.assignVoucher") }}</span>
                    </v-tooltip>
                    <!-- Request voucher -->
                    <v-tooltip
                      v-if="!props.item.isDisabled && props.item.type != VoucherType.PROMOTION && !isSupplier() && !isAccountant() && hasAction('create_request') && !isEntityDisabled()"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="success"
                          dark
                          v-on="on"
                          @click="requestEVoucherToParent($event, props.item)"
                        >
                          <v-icon size="15">mdi-message-bulleted</v-icon>
                        </v-btn>
                      </template>
                      <span style="text-transform: none;">{{
                        $t("issueVoucherRequest.requestTitle")
                      }}</span>
                    </v-tooltip>
                    <!-- Delete voucher -->
                    <v-tooltip
                      v-if="!props.item.isDisabled && !isAccountant() && hasAction('delete_issuance') && !isEntityDisabled() || isAdmin()"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalDeleteEVoucherIssuance(props.item.id)"
                        >
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.delete") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <div
                  v-show="(isSupplier() || isAdmin())"
                  class="py-2 mx-3"
                  style="display: flex; white-space: nowrap; padding-left: 110px"
                  @mouseleave="props.expanded=false">
                  <action-item
                    first
                    class="text-info"
                    @click="onShowModalEditEVoucherIssuance(props.item)">
                    {{ $t('common.viewDetail') }}
                  </action-item>
                  <action-item
                    v-if="!props.item.isDisabled && !isAccountant() && !isEntityDisabled()"
                    class="text-red"
                    @click="onShowModalDisabledIssuance(props.item.id)">
                    {{ $t('entity.disabled') }}
                  </action-item>
                  <action-item
                    v-else-if="!isAccountant() && !isEntityDisabled()"
                    class="text-success"
                    @click="onShowModalEnableIssuance(props.item.id)">
                    {{ $t('entity.state.active') }}
                  </action-item>
                  <action-item
                    v-if="!isAccountant() && (props.item.status === VoucherIssuanceStatusType.WAIT_FOR_APPROVAL) && !isEntityDisabled()"
                    class="text-blue"
                    @click="onShowModalApprovalIssuance(props.item.id)">
                    {{ $t('common.approval') }}
                  </action-item>
                  <action-item
                    v-if="isSupplier() && !isAccountant() && !isEntityDisabled() && (hasAction('create_issuance') || (!forConasi && hasAction('create_voucher')))"
                    class="text-warning"
                    @click="onShowModalCloneIssuance(props.item.id)">
                    {{ $t('common.clone') }}
                  </action-item>
                  <action-item
                    v-if="isSupplier() && !isAccountant() && !isEntityDisabled() && !forConasi"
                    class="text-info darken-3"
                    @click="onShowModalExternExpiredDays($event, props.item.id)">
                    {{ $t('evoucher.issuance.extendExpiredDate') }}
                  </action-item>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="voucherIssuancePaginate.totalPage"
      :current-page="voucherIssuancePaginate.currentPage"
      :row-per-page="voucherIssuancePaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="actionTypeSelect == actionType.DELETE ? onDeleteEVoucherIssuance() : (actionTypeSelect == actionType.DISABLE ? onDisabledIssuance() : onEnableIssuance())"
    />
    <loading-bar :is-loading="isLoading"/>
    <add-issuance-and-voucher
      ref="addIssuanceAndVoucher"
      @createEVoucherSuccess="onCreateEVoucherSuccess"
    />
    <transferVoucherBetweenAgentModal ref="transferVoucherBetweenAgent" />
    <addEVoucherList
      ref="addEVoucherList"
      @createEVoucherSuccess="onCreateEVoucherSuccess"
    />
    <assignVoucherModal
      ref="assignVoucherModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess"
      @onCreateEVoucher="onShowModalAddListEvoucher"
    />
    <requestEVoucherToParent ref="requestEVoucherToParent" />
    <extend-expired-day-modal ref="extendExpiredDayModal" />
    <assignVoucherByExcelModeModal
      ref="assignVoucherByExcelModeModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess" />
    <confirm-modal
      ref="approvalIssuanceModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmApprovalIssuance"
    />
  </v-container>
</template>
<script>
import AddIssuanceAndVoucher from './AddIssuanceAndVoucher'
import { mapActions, mapGetters } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
// import dateUtils from 'utils/dateUtils'
import VoucherIssuanceStatusType from 'enum/voucherIssuanceStatusType'
import VoucherType from 'enum/voucherType'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import stringUtils from 'utils/stringUtils'
import ConfirmModal from 'Components/ConfirmModal'
import TransferVoucherBetweenAgentModal from './TransferVoucherBetweenAgentModal'
import AddEVoucherList from './AddEvoucherList'
import CreateVoucherType from 'enum/createVoucherType'
import AssignVoucherModal from './AssignVoucherModal'
import EntityType from 'enum/entityType'
import RequestEVoucherToParent from './RequestEVoucherToParent'
import VoucherModel from 'enum/voucherModel'
import ExtendExpiredDayModal from 'Views/EVoucher/ExtendExpiredDayModal.vue'
import ActionItem from 'Components/ActionItem.vue'
import AssignVoucherByExcelModeModal from './AssignVoucherByExcelMode'
import EntityRoleType from 'enum/entityRoleType'
export default {
  components: {
    AddIssuanceAndVoucher,
    ConfirmModal,
    TransferVoucherBetweenAgentModal,
    AddEVoucherList,
    AssignVoucherModal,
    RequestEVoucherToParent,
    ExtendExpiredDayModal,
    ActionItem,
    AssignVoucherByExcelModeModal
  },
  data: () => ({
    workpieceList: [],
    voucherIssuanceHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.image',
        value: 'image',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.code',
        value: 'code',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.name',
        value: 'name',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.numberOfWorkpiece',
        value: 'total',
        align: 'center'
      },
      {
        sortable: false,
        text: 'report.quantityVoucherSupplierToAgent.issueNumber',
        value: 'quantityDistributed',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.numberOfWorkpieceRemaining',
        value: 'quantityWorkpieceRemain',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.distributedToTheAgent',
        value: 'quantityDistributedToAgent',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.distributedToTheCustomer',
        value: 'quantityDistributedToCustomer',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.numberOfUsedVoucher',
        value: 'quantityOfUsed',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.type',
        value: 'type',
        align: 'center'
      },
      {
        sortable: false,
        text: 'Voucher',
        value: 'voucher',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.pricePercent',
        value: 'value',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    voucherIssuancePaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    voucherSearch: {
      workpieceCode: null,
      issuanceStatus: null,
      issuanceType: VoucherType.VOUCHER,
      issuanceModel: VoucherModel.NORMAL
    },
    typeList: stringUtils.VoucherTypeList,
    confirmModalTitle: '',
    status: stringUtils.DistributionStatus,
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    selectedWorkpieceId: null,
    isLoading: false,
    createVoucherType: CreateVoucherType,
    entityType: EntityType,
    forConasi: FOR_CONASI_TARGET,
    VoucherType: VoucherType,
    noImg: require('../../assets/noimage.png'),
    actionType: {
      DELETE: 1,
      DISABLE: 2,
      ENABLE: 3
    },
    actionTypeSelect: null,
    modelList: stringUtils.VoucherModelList,
    VoucherModel: VoucherModel,
    VoucherIssuanceStatusType: VoucherIssuanceStatusType
  }),
  computed: {
    ...mapGetters([
      'VOUCHER_ISSUANCES_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    VOUCHER_ISSUANCES_DATA: function () {
      let res = this.VOUCHER_ISSUANCES_DATA
      // Handle paginate
      this.voucherIssuancePaginate.currentPage = res.paginate.currentPage
      this.voucherIssuancePaginate.totalPage = res.paginate.totalPage
      this.voucherIssuancePaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherIssuanceData = res.results
      this.workpieceList = []
      for (let i = 0, size = voucherIssuanceData.length; i < size; i++) {
        let voucherIssuanceObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: voucherIssuanceData[i].id,
          name: voucherIssuanceData[i].name,
          code: voucherIssuanceData[i].code,
          quantity: voucherIssuanceData[i].quantity,
          status: voucherIssuanceData[i].status,
          type: voucherIssuanceData[i].type,
          value: voucherIssuanceData[i].value,
          statisticsQuantity: voucherIssuanceData[i].statistics_quantity,
          image: voucherIssuanceData[i].image_url,
          isDisabled: voucherIssuanceData[i].is_disabled,
          voucher: voucherIssuanceData[i].model,
          quantityRemainOfAgent: voucherIssuanceData[i].statistics_quantity.quantity_remain_of_agent
        }
        this.workpieceList.push(voucherIssuanceObj)
      }
      this.isLoading = false
    }
  },
  created () {
    let routerQuery = this.$route.query
    this.voucherSearch.workpieceCode = functionUtils.getStringValue(routerQuery && routerQuery.code)
    this.voucherSearch.issuanceStatus = functionUtils.getNumberValue(routerQuery && routerQuery.status)
    this.startDateFormatted = functionUtils.getStringValue(routerQuery && routerQuery.fromDate)
    this.endDateFormatted = functionUtils.getStringValue(routerQuery && routerQuery.toDate)
    this.voucherSearch.issuanceType = functionUtils.getNumberValue(routerQuery && routerQuery.type, VoucherType.VOUCHER)
    this.voucherSearch.issuanceModel = functionUtils.getNumberValue(routerQuery && routerQuery.model, VoucherModel.NORMAL)
    // this.voucherIssuancePaginate.currentPage = functionUtils.getNumberValue(routerQuery && routerQuery.page, 1)
    this.getEVoucherIssuance()
    if (!(this.isSupplier() || this.isAdmin())) {
      this.voucherIssuanceHeaders = this.voucherIssuanceHeaders.filter(element => {
        return element.value !== 'total' && element.value !== 'quantityWorkpieceRemain'
      })
      for (let i = 0, size = this.voucherIssuanceHeaders.length; i < size; i++) {
        if (this.isAgent()) {
          if (this.voucherIssuanceHeaders[i].value === 'quantityDistributedToAgent') {
            this.voucherIssuanceHeaders[i].text = 'promotionVoucher.remainQuantity'
          }
        }
        if (this.voucherIssuanceHeaders[i].value === 'quantityDistributed') {
          this.voucherIssuanceHeaders[i].text = 'evoucher.issuance.quantityReceived'
        }
        if (this.voucherIssuanceHeaders[i].value === 'quantityDistributedToCustomer') {
          this.voucherIssuanceHeaders[i].text = 'voucherHistory.receivingCustomer'
        }
      }
    }
    if (!(this.forConasi || this.isAdmin())) {
      this.voucherIssuanceHeaders = this.voucherIssuanceHeaders.filter(element => {
        return element.value !== 'quantityDistributed' && element.value !== 'quantityWorkpieceRemain'
      })
      for (let i = 0, size = this.voucherIssuanceHeaders.length; i < size; i++) {
        if (this.voucherIssuanceHeaders[i].value === 'total') {
          this.voucherIssuanceHeaders[i].text = 'evoucher.issuance.quantity'
        }
      }
    }
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    /**
     * Get evoucher model
     */
    getVoucherModel: function (model) {
      // eslint-disable-next-line eqeqeq
      if (model == VoucherModel.NORMAL) {
        return 'Thường'
      // eslint-disable-next-line eqeqeq
      } else if (model == VoucherModel.PRODUCT) {
        return 'Sản phẩm'
      } else {
        return 'Thường'
      }
    },
    /**
     * Enable issuance
     */
    onEnableIssuance: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        isDisabled: false
      }
      this.ON_ENABLE_DISABLE_ISSUANCE({ id: this.selectedWorkpieceId, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getEVoucherIssuance()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    onShowModalCloneIssuance: function (workpieceId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.addIssuanceAndVoucher.onShowModal(workpieceId)
    },
    onShowModalExternExpiredDays: function (event, workpieceId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      event.preventDefault()
      this.$refs.extendExpiredDayModal.onShowModal(null, workpieceId, null)
    },
    /**
     * Show modal enable issuance
     */
    onShowModalEnableIssuance: function (workpieceId) {
      this.actionTypeSelect = this.actionType.ENABLE
      this.confirmModalTitle = this.$t('evoucher.issuance.areYouSureYouWantToReactivateWorkpiece')
      this.selectedWorkpieceId = workpieceId
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Show modal disable issuance
     */
    onShowModalDisabledIssuance: function (workpieceId) {
      this.actionTypeSelect = this.actionType.DISABLE
      this.confirmModalTitle = this.$t('evoucher.issuance.areYouSureYouWantToDisableWorkpiece')
      this.selectedWorkpieceId = workpieceId
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Disabled issuance
     */
    onDisabledIssuance: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        isDisabled: true
      }
      this.ON_ENABLE_DISABLE_ISSUANCE({ id: this.selectedWorkpieceId, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getEVoucherIssuance()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Check is null
     */
    isNull: function (value) {
      return functionUtils.isNull(value)
    },
    /**
     * Show modal add list e voucher
     */
    onShowModalAddListEvoucher: function (createVoucherType, workpieceId, issuanceId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.addEVoucherList.onShowModal(createVoucherType, workpieceId, issuanceId)
    },
    /**
     * Request e voucher to parent
     */
    requestEVoucherToParent: function (event, item) {
      event.preventDefault()
      let workpieceId = item.id
      let sendFromIssuance = true
      this.$refs.requestEVoucherToParent.onShowModal(workpieceId, sendFromIssuance)
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Check is agent
     */
    isAgent: function () {
      return functionUtils.isEntityType(EntityType.AGENT)
    },
    /**
     * Transaction voucher success
     */
    onTransactionVoucherSuccess: function () {
      this.getEVoucherIssuance()
    },
    /**
    * Show modal assign voucher
    */
    onShowModalAssignVoucher: function (item) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (item) {
        this.$refs.assignVoucherModal.onShowModal([], item.id)
      } else {
        this.$refs.assignVoucherModal.onShowModal([])
      }
    },
    /**
     * Show modal add voucher with type list
     */
    onShowModalAddEVoucher: function (createType, workpiece) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.addEVoucherList.onShowModal(createType, workpiece.id)
    },
    /**
     * Create voucher success
     */
    onCreateEVoucherSuccess: function () {
      this.getEVoucherIssuance()
    },
    /**
     * Transfer voucher between agent modal
     */
    onShowTransferVoucherBetweenAgentModal: function () {
      this.$refs.transferVoucherBetweenAgent.onShowModal()
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isAccountant: function () {
      return functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)
    },
    onSearch: function () {
      this.$router.replace({
        query: {
          code: functionUtils.getStringValue(this.voucherSearch.workpieceCode),
          status: this.voucherSearch.issuanceStatus,
          fromDate: functionUtils.getStringValue(this.startDateFormatted),
          toDate: functionUtils.getStringValue(this.endDateFormatted),
          type: this.voucherSearch.issuanceType,
          model: this.voucherSearch.issuanceModel
          // page: this.voucherIssuancePaginate.currentPage
        }
      })
      this.getEVoucherIssuance()
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    onShowModalDeleteEVoucherIssuance: function (id) {
      this.actionTypeSelect = this.actionType.DELETE
      this.confirmModalTitle = this.$t('evoucher.issuance.confirmDeleteEvoucherIssuanTitle')
      this.selectedWorkpieceId = id
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete e voucher issuance
     */
    onDeleteEVoucherIssuance: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.DELETE_VOUCHER_ISSUANCE({ id: this.selectedWorkpieceId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getEVoucherIssuance()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Get value
     */
    getValue: function (value, type) {
      if (type === VoucherType.VOUCHER) {
        return functionUtils.convertFormatNumberToMoney(value)
      }
      return value
    },
    /**
     * Get e voucher issuance
     */
    getEVoucherIssuance: function () {
      let filter = {
        params: {
          page: this.voucherIssuancePaginate.currentPage,
          workpieceCode: functionUtils.getStringValue(this.voucherSearch.workpieceCode),
          issuanceStatus: this.voucherSearch.issuanceStatus,
          startDate: this.startDateFormatted,
          endDate: this.endDateFormatted,
          issuanceType: this.voucherSearch.issuanceType,
          includeDisabled: true
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_ISSUANCE_LIST(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Get e voucher type
     */
    getVoucherType: function (type) {
      if (type === VoucherType.VOUCHER) {
        if (this.forConasi) {
          return 'Voucher'
        }
        return this.$t('evoucher.discountByPrice')
      } else if (type === VoucherType.PROMOTION) {
        return this.$t('evoucher.issuance.promotion')
      } else if (type === VoucherType.COUPON) {
        if (this.forConasi) {
          return 'Coupon'
        }
        return this.$t('evoucher.discountByPercent')
      } else {
        return ''
      }
    },
    /**
     * Get e voucher status type
     */
    getVoucherIssuanceStatusType: function (status) {
      if (status === VoucherIssuanceStatusType.ACTIVATED) {
        return 'user.status_data.distribution'
      } else {
        return 'user.status_data.noDistribution'
      }
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.voucherIssuancePaginate.currentPage = page
      this.getEVoucherIssuance()
    },
    /**
     * Show modal add e voucher issuance
     */
    onShowModalAddEVoucherIssuance: function () {
      this.$refs.addIssuanceAndVoucher.onShowModal(null)
    },
    /**
     * Redirect to screen voucher issuance info
     */
    onShowModalEditEVoucherIssuance: function (item) {
      this.$router.push({
        name: 'sideBar.voucherIssuanceDetail',
        params: {
          id: item.id
        }
      })
    },
    /**
     * Check entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    /**
     * Show modal deliver voucher by excel mode
     */
    onShowModalDeliveryVoucherByExcelMode: function () {
      this.$refs.assignVoucherByExcelModeModal.onShowModal()
    },
    onShowModalApprovalIssuance: function (workpieceId) {
      this.selectedWorkpieceId = workpieceId
      this.confirmModalTitle = this.$t('entity.notiApproveVoucherCreation')
      this.$refs.approvalIssuanceModal.onShowModal()
    },
    onConfirmApprovalIssuance: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.APPROVAL_VOUCHER_ISSUANCE({ id: this.selectedWorkpieceId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getEVoucherIssuance()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_VOUCHER_ISSUANCE_LIST',
      'DELETE_VOUCHER_ISSUANCE',
      'ON_SHOW_TOAST',
      'ON_ENABLE_DISABLE_ISSUANCE',
      'APPROVAL_VOUCHER_ISSUANCE'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
</style>
