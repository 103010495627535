<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'800px'"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.transferVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md6>
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="workpieceId"
                  :items="workpieceList"
                  :clearable="true"
                  :label="$t('evoucher.issuance.workpiece')"
                  type="text"
                  class="required"
                  @change="voucherIssuanceChange()"
                >
                  <template v-slot:item="data">
                    <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  v-model="quantityVoucherAvailableOfEntity"
                  :label="$t('evoucher.numberOfVouchersAvailable')"
                  :name="$t('evoucher.numberOfVouchersAvailable')"
                  disabled
                  type="text"
                />
              </v-flex>
              <!-- <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  v-model="agentCodeOrEmail"
                  :label="$t('voucherTransferRequest.agentCodeOrEmail')"
                  :name="$t('voucherTransferRequest.agentCodeOrEmail')"
                  type="text"
                />
              </v-flex> -->
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero, ruleMustLessThanAvailableEVoucher]"
                  v-model="numberOfTransfer"
                  :label="$t('issueVoucherRequest.quantity')"
                  :name="$t('issueVoucherRequest.quantity')"
                  type="number"
                  class="required"
                />
              </v-flex>
              <!-- Agent -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="agentId"
                  :items="agents"
                  :clearable="true"
                  :label="$t('assignVoucher.selectAgent')"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UploadExcel from 'Components/UploadExcel'
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
export default {
  components: {
    UploadExcel
  },
  data () {
    return {
      valid: false,
      workpieceId: null,
      workpieceList: [],
      quantityVoucherAvailableOfEntity: 0,
      isShowModal: false,
      agentCodeOrEmail: null,
      numberOfTransfer: null,
      isLoading: false,
      agents: [],
      agentId: null
    }
  },
  computed: {
    ...mapGetters([
      'VOUCHER_ISSUANCES_DATA',
      'QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA',
      'AGENT_SAME_PARENT_DATA'
    ])
  },
  watch: {
    VOUCHER_ISSUANCES_DATA () {
      let data = this.VOUCHER_ISSUANCES_DATA
      this.workpieceList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          text: data[i].name,
          code: data[i].code,
          value: data[i].id
        }
        this.workpieceList.push(obj)
      }
    },
    QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA () {
      let data = this.QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA
      this.quantityVoucherAvailableOfEntity = data.quantity_voucher_available
        ? data.quantity_voucher_available
        : 0
    },
    AGENT_SAME_PARENT_DATA () {
      let data = this.AGENT_SAME_PARENT_DATA
      this.agents = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          text: data[i].name
        }
        this.agents.push(obj)
      }
    }
  },
  methods: {
    // Not greater than remain e voucher
    ruleMustLessThanAvailableEVoucher (value) {
      return validationRules.ruleMustLessThanOrEqual(value, this.quantityVoucherAvailableOfEntity)
    },
    // Greater than zero
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Confirm deliver voucher to customer by excel mode
     */
    onConfirm () {
      if (this.$refs.form.validate()) {
        let filter = {
          // emailCodeAgent: this.agentCodeOrEmail,
          numberOfTransfer: this.numberOfTransfer,
          workpieceId: this.workpieceId,
          agentId: this.agentId
        }
        this.isLoading = true
        this.TRANSFER_TO_ANOTHER_AGENT_SAME_LEVEL(filter).then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.setDefaultData()
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isShowModal = false
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.isShowModal = true
      this.getEVoucherIssuanceList()
      // Get agents same level
      this.GET_AGENT_SAME_PARENT()
    },
    /**
     * Get e voucher issuance list
     */
    getEVoucherIssuanceList: function () {
      this.GET_VOUCHER_ISSUANCE_LIST()
    },
    /**
     * E voucher issuance change
     */
    voucherIssuanceChange: function () {
      this.getQuantityEVoucherAvailableOfEntity(this.workpieceId)
    },
    /**
     * Get quantity e voucher available of entity
     */
    getQuantityEVoucherAvailableOfEntity: function (workpieceId) {
      let filter = {}
      if (!functionUtils.isNull(workpieceId)) {
        filter = {
          params: {
            workpieceId: workpieceId
          }
        }
      } else {
        filter = {
          params: {
          }
        }
      }
      this.GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY(filter)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    ...mapActions([
      'GET_VOUCHER_ISSUANCE_LIST',
      'GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY',
      'TRANSFER_TO_ANOTHER_AGENT_SAME_LEVEL',
      'ON_SHOW_TOAST',
      'GET_AGENT_SAME_PARENT'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
